@import '../../../styles/variables.scss';
@import '../form-mixin.scss';

.container-input-password{
  position: relative;

  label{
    @include label;
  }

  &.inline {
    @include inline;
  }

  &:not(.inline){
    label+.container-input {
      margin-top: 6px;
    }
  }

  .input {
    @include input;
  }
  &.primary {
    @include primary;
  }
  &.login {
    @include login;
  }

}

.container-input{
  position: relative;
  width: 100%;

  .container-icon{
    display: flex;
    position: absolute;
    justify-content: center;
    align-content: center;
    top:-2px;
    right:0;
    height: 36px;
    width: 36px;
    cursor: pointer;
    svg {
      color: #84899A;
    }
  }

}

.container-input .input {

  &::-ms-reveal,
  &::-ms-clear{
    display: none;
  }

  // HIDE KEY ON SAFARI
  &::-webkit-credentials-auto-fill-button {
    margin: 0;
    width: 0;
    background-color: transparent;
  }
  
}
