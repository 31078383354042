@import "../../styles/_variables.scss";
.container {
  max-height: calc(100vh - 120px);
  overflow-y: auto;
  width: 250px;
  @include scrollbar;
  .content {
    border-top: $border;
    section {
      padding: 20px;
      border-bottom: $border;
      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        h4 {
          font-family: "Jost-Regular";
          font-size: $h2;
          color: $text-color-light;
          margin: 0;
          span {
            font-size: $small;
            color: $text-color;
          }
        }
      }
      .containerList {
        display: none;
        &.opened {
          display: block;
          margin-top: $margin-sm;
        }
        ul {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-gap: $margin-xs;
          padding: 0;
          margin: 0 0 $margin-sm 0;
          li {
            list-style: none;
            position: relative;
            .templateItem {
              background-color: rgba(255, 255, 255, 0.2);
              padding: 10px;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
            .btn {
              position: absolute;
              bottom: 0px;
              right: 0px;
              @include btn;
              &:last-child {
                left: 0px;
              }
            }
          }
        }
      }
    }
  }
  .add {
    @include btn;
    @include regular;
    font-size: 16px;
    color: $white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 15px;
    color: $text-color;
    &:hover {
      color: $primary-color;
    }
    svg {
      font-size: 20px;
    }
  }
}
