@import "../../styles/_variables.scss";

.loader {
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
  width: 48px;
  height: 48px;
  border: 5px solid $primary-color;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
  } 
