@import '../../../styles/variables.scss';
@import '../form-mixin.scss';

.container-input{
  position: relative;
  width: 100%;
  label{
    @include label;
  }

  &.inline {
    @include inline;
    justify-content: flex-start;
    .color {
      margin-top: 0px;
    }
  }

  &:not(.inline){
    label+.input {
      margin-top: 0px;
    }
  }

  .input {
    @include input;
    position: absolute;
    max-width: 100px;
    opacity: 0;
  }
  .text {
    @include input;
    font-size: 14px;
    margin-left: 12px;
    max-width: calc(100% - 120px);
    padding: 7px 10px;
    text-align: center;
    border-radius: 3px;
    background-color: #1F232E;
  }
  .color {
    pointer-events: none;
    margin-top: 5px;
    width: 30px;
    max-width: 100%;
    height: 30px;
    border-radius: 3px;
  }
  &.primary {
    @include primary;
  }
  &.login {
    @include login;
  }

}
