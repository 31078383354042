@import "../../styles/_variables.scss";

.container {
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden;
  .sceneContainer{
    position: relative;
  }
}