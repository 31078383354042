@mixin page-container {
  height: 100vh;
  width: 100%;
}

@mixin transition {
  transition: all 0.2s ease-in-out;
}

@mixin input {
  @include transition;
  display: block;
  padding: 6px 0;
  border: none;
  outline: none;
  border-radius: 0px;
  width: 100%;
  // min-width: 200px;
  caret-color: $white;
  box-sizing: border-box;
  background-color: transparent;
  border-bottom: solid 1px transparent;
  font-size: $regular;
  line-height: 17px;
  color: $white;
  &:focus{
    border-bottom: solid 1px rgba($white , 0.2);
  }
  &::placeholder {
    color : #84899A;
  }
}

@mixin  flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
} 

@mixin btn {
	cursor: pointer;
	transition: all 0.2s ease-in-out;
	background-color: transparent;
  outline: none;
  border: none;
  &.primary {
    background-color: $primary-color;
    border: none;
    padding: $margin-xs $margin-md;
    font-size: $regular;
    color: $white;
    border-radius: $radius;
    cursor: pointer;
  }
  &.secondary {
    background-color: rgba($primary-color, 0.2);
    color: $primary-color;
    border: none;
    padding: $margin-xs $margin-md;
    font-size: $regular;
    border-radius: $radius;
    cursor: pointer;
  }
  &.primary-icon {
    width: 37px;
    height: 37px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    border: none;
    font-size: 20px;
    color: $text-color;
    border-radius: $radius;
    cursor: pointer;
    &:hover {
      background-color: rgba($primary-color, 0.2);
      color: $primary-color;
    }
    &.active {
      background-color: rgba($primary-color, 0.2);
      color: $primary-color;
    }
  }
}

@mixin aside {
  width: 250px;
  height: 100%;
  border-right: $border;
  color: #84899A;
}

@mixin container-field {
  margin-bottom: 20px;
}

@mixin avatar {
  width: 27px;
  height: 27px;
  border-radius: 27px;
  border: 1px solid $white;
  background-color: $dark;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba($white, 0.3);
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: $primary-color;
  }
}