@import '../../styles/_variables.scss';

.container {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  top: 0px;
  left: 250px;
  width: calc(100% - 250px);
  z-index: 100;
  .title {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    p {
      @include regular;
      margin: 0px;
      min-width: 65px;
      &:last-child {
        @include regular;
        margin-left: 5px;
      } 
    }
    input {
      transform: translateY(2px);
      margin-left: 5px;
      @include input;
      @include regular;
    }
    .loader {
      width: 26px;
      min-width: 26px;
      height: 26px;
      border: 3px solid $primary-color;
      border-bottom-color: transparent;
      border-radius: 26px;
      display: inline-block;
      box-sizing: border-box;
      animation: rotation 1s linear infinite;
    }
  }
  .actions {
    padding: 10px 20px;
    display: flex;
    .btn {
      @include btn;
      margin-left: 5px;
    }
  }
}

@keyframes rotation {
  0% {
      transform: translate(5px, 4px) rotate(0deg);
  }
  100% {
      transform: translate(5px, 4px) rotate(360deg);
  }
} 