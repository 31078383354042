@import "../../styles/_variables.scss";

.container {
  height: 100vh;
  width: 100%;
  padding: 20px;
  @include flex-center;
  p {
    text-align: center;
    font-size: 18px;
  }
}