@import '../../styles/_variables.scss';
.container {
  width: 100%;
  input {
    display: none;
  }
  .btn {
    @include btn;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &.secondary {
      background-color: $dark-grey;
      border-radius: $radius;
      color: #84899A;
      font-size: 14px;
      padding: 10px;
    }
  }
}