@import '../../styles/fonts.scss';
@import '../../styles/_variables.scss';

.container {
  margin-bottom: 15px;
  border-radius: $radius;
  .input {
    display: flex;
    justify-content: space-between;
    .btn {
      @include btn;
      &.primary-icon {
        min-width: 32px;
        height: 32px;
      }
      &.disabled {
        pointer-events: none;
        opacity: 0;
      }
    }
  }
  .colorChart {
    margin: 10px 0px 10px 69px;
    display: flex;
    flex-wrap: wrap;
    .color {
      margin: 0px 6px 6px 0px;
      position: relative;
      &:hover {
        .delete {
          display: block;
          top: 22px;
        }
      }
      .value {
        @include btn;
        width: 20px;
        height: 20px;
        border-radius: 3px;
        z-index: 200px;
      }
      .delete {
        @include btn;
        position: absolute;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px;
        top: 0px;
        left: 0px;
        font-size: 18px;
        // display: none;
      }
    }
  }
}