@import '../../styles/_variables.scss';
.container {
  @include aside;
  .logo {
    @include logo;
    display: block;
    text-decoration: none;
    margin: 20px;
    color: $white;
  }
  .search {
    padding: 0px 20px 20px;
    border-bottom: $border;
    display: flex;
    align-items: center;
    input {
      margin-left: 10px;
      @include input;
    }
  }
  .project {
    padding: 10px 20px;
    a {
      color: $white;
      text-decoration: none;
      @include transition;
      &:hover {
        color: $primary-color;
      }
      h2 {
        @include regular;
        font-size: 18px;
        margin: 0px;
      }
    }
  }
  ul {
    list-style: none;
    margin: 0px;
    padding: 0px 0px 0px 20px;
    max-height: calc(100% - 165px);
    overflow-y: auto;
    @include scrollbar;
    li {
      margin: 10px 0px;
      a {
        @include transition;
        text-decoration: none;
        font-size: 13px;
        display: flex;
        align-items: center;
        margin: 0px;
        svg {
          margin-right: 5px;
        }
        &:hover {
          color: $primary-color;
        }
      }
    }
  }
}