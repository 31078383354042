@import '../../../styles/variables.scss';
@import '../form-mixin.scss';

.container-input{

  label{
    @include label;
  }

  &.inline {
    @include inline;
  }

  &:not(.inline){
    label+.input {
      margin-top: 0px;
    }
  }

  .input {
    @include input;
  }
  &.primary {
    @include primary;
  }
  &.login {
    @include login;
  }

}
