@import '../../styles/fonts.scss';
@import '../../styles/_variables.scss';

.container {
  margin-bottom: 15px;
  label {
    font-size: 14px;
    color: #84899A;
    display: block;
    margin-bottom: 10px;
  }
  ul {
    margin-top: 10px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: $margin-xs;
    padding: 0;
    margin: 0 0 $margin-sm 0;
    li {
      list-style: none;
      position: relative;
      .templateItem {
        @include btn;
        border: $border;
        padding: 10px;
        border-radius: 4px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}