@import "../../styles/_variables.scss";

.container {  
  height: 100%;
  width: 100%;
  color: $white;
  padding-top: 50px;
  h2 {
    @include h1;
    padding: 0px 50px;
  }
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 50px;
    h3 {
      @include regular;
      font-size: 18px;
    }
    button {
      margin-bottom: 20px;
      .add {
        display: flex;
        align-items: center;
      }
    }
  }
  .projects {
    max-height: calc(100% - 140px);
    overflow-y: auto;
    display: grid;
    padding: 0px 50px;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    grid-auto-rows: minmax(100px, auto);
    @include scrollbar;
    @media only screen and (min-width: $md-screen) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media only screen and (min-width: $lg-screen) {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}