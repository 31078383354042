@import '../../styles/_variables.scss';
.container {
  max-height: calc(100vh - 120px);
  overflow-y: auto;
  @include scrollbar;
  width: 250px;
  .scenes {
    display: flex;
    flex-direction: column;
    a {
      text-decoration: none;
      margin: 0px;
      padding: 10px 20px;
      border-top: $border;
      &:hover {
        background-color: rgba($white, 0.06);
      }
    }
  }
  .title { 
    padding: 10px 10px 10px 20px;
    border-top: $border;
    display: flex;
    justify-content: space-between;
    p {
      color: $white;
      margin: 0px;
    }
    input {
      transform: translateY(2px);
      margin-left: 5px;
      @include input;
      @include regular;
      padding: 0px
    }
    .btn {
      @include btn;
      min-width: 37px;
    }
  }
  .list {
    .btn {
      width: 100%;
      @include btn;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 10px 20px;
      p {
        @include text;
        color: #84899A;
        font-size: 14px;
        line-height: 14px;
        margin: 0px;
      }
      svg {
        color: #84899A;
        font-size: 16px;
        margin-right: 5px;
      }
      opacity: 0.8;
      &:hover {
        p {
          color: $primary-color;
        }
        svg {
          color: $primary-color;
        }
      }
    }
  }
}

.item {
  display: flex;
  cursor: pointer;
  position: relative;
  .comments {
    position: absolute;
    @include flex-center;
    top: 6px;
    right: 8px;
    font-size: 13px;
    svg {
      margin-left: 5px;
      font-size: 16px;
      color: rgba($white, 0.4);
    }
  }
  &.isGroup {
    .color {
      display: none;
    }
    &.active {
      background-color: transparent;
    }
    &:hover {
      background-color: transparent;
    }
    .label {
      svg {
        transform: scale(0.2);
      }
    }
  }
  &.isLinkGroup {
    .color {
      display: none;
    }
    &:hover {
      background-color: transparent;
    }
    &.active {
      background-color: transparent;
    }
  }
  &:hover {
    background-color: rgba($white, 0.06);
    color: $white;
  }
  .color {
    width: 6px;
    margin-right: 16px;
    opacity: 0.3;
  }
  .label {
    display: flex;
    align-items: center;
    padding: 5px 0px;
    p {
      font-size: 14px;
      margin: 0px 5px;
    }
  }
  &.active {
    background-color: rgba($white, 0.08);
    .label {
      p {
        color: $white;
      }
    }
    .color {
      opacity: 1;
    }
  }
}