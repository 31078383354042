@import '../../styles/_variables.scss';
.container {
  width: 250px;
  display: flex;
  flex-direction: column;
  .add {
    width: calc(100% - 42px);
    @include btn;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 20px;
    margin: 0px auto 10px;
    border: 1px solid #84899A;
    border-radius: 3.5px;
    p {
      @include text;
      color: #84899A;
      font-size: 14px;
      line-height: 14px;
      margin: 0px;
    }
    svg {
      color: #84899A;
      font-size: 16px;
      margin-right: 5px;
    }
    opacity: 0.6;
    &:hover {
      background-color: rgba(#84899A, 0.2);
    }
  }
  .list {
    max-height: calc(100vh - 160px);
    overflow-y: auto;
    @include scrollbar;
    .group {
      margin-bottom: 10px;
      padding: 0px 20px;
      display: flex;
      align-items: flex-start;
      .toggle {
        @include transition;
        background-color: transparent;
        cursor: pointer;
        border: none;
        @include flex-center;
        width: 24px;
        min-height: 24px;
        font-size: 20px;
        color: #84899A;
        padding: 0px;
        &.open {
          transform: rotate(180deg);
        }
      }
      .content {
        width: 100%;
        padding-left: 1px;
        .header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-left: 4px;
          cursor: pointer;
          .eye {
            padding-top: 2px;
            font-size: 18px;
          }
          .title {
            display: flex;
            align-items: flex-start;
            &:hover {
              p {
                text-decoration: underline;
              }
            }
            .puce {
              width: 18px;
              min-width: 18px;
              height: 18px;
              margin-top: 2px;
              margin-left: 2px;
              border-radius: 2px;
              background-color: red;
            }
            p {
              margin: 2px 0px 0px 8px;
              font-size: 14px;
            }
          }
        }
        .panel {
          margin-top: 8px;
          padding-left: 3px;
          overflow: hidden;
          @include transition;
        }
      }
    }
  }
}