@import "../../styles/_variables.scss";

.container {  
  height: 100%;
  width: 100%;
  padding: 50px;
  color: $white;
  h2 {
    @include medium;
    font-size: 28px;
  }
  h3 {
    @include regular;
    font-size: 18px;
  }
  .container-field {
    @include container-field;
    background-color: $dark-grey;
    padding: 10px 15px;
    border-radius: $radius;
  }
  .row {
    display: flex;
    .col {
      &:first-child {
        width: 350px;
      }
      .profile-picture {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: $margin-md;
        .avatar {
          width: 120px;
          height: 120px;
          border-radius: 120px;
          border: 1px solid $white;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .actions {
          width: 200px;
          flex-direction: column;
        }
      }
    }
  }
}