@import '../../styles/_variables.scss';
.container {
  position: absolute;
  left: calc(50% - 110px);
  top: -1px;
  border: $border;
  background-color: $dark;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 10px 10px 10px 10px;
  display: flex;
  justify-content: space-between;
  width: 180px;
  .btn {
    @include btn;
    path {
      @include transition;
    }
    &.primary-icon {
      &:not(.active) {
        &:hover {
          background-color: rgba($white, 0.1);
        }
      }
    }
  }
}