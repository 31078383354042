@import "../../styles/_variables.scss";

.container {  
  height: 100%;
  width: 100%;
  padding: 50px;
  color: $white;
  h2 {
    @include medium;
    font-size: 28px;
  }
  h3 {
    @include regular;
    font-size: 18px;
  }
  .container-field {
    @include container-field;
    background-color: $dark-grey;
    padding: 10px 15px;
    border-radius: $radius;
  }
  .row {
    display: flex;
    .col {
      &:first-child {
        width: 350px;
      }
      .picture {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: $margin-md;
        .thumbnail {
          width: 100%;
          height: 180px;
          border: 1px solid $dark-grey;
          margin-bottom: 20px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .actions {
          width: 200px;
          flex-direction: column;
        }
      }
    }
    .btns {
      margin-top: 20px;
      display: flex;
      .delete {
        margin-left: 10px;
        @include btn;
        @include text;
        display: flex;
        align-items: center;
        color: rgba($white, 0.5);
        min-width: 155px;
        padding: $margin-xs $margin-md;
        svg {
          margin-left: 5px;
          font-size: 20px;
        }
        &:hover {
          color: $danger;
        }
      }
    }
  }
}