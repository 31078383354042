@import "../../styles/_variables.scss";

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-height: 100%;
  .form {
    width: 100%;
    padding: 0% 30px;
    animation: fadeIn 0.3s linear;
    animation-fill-mode: both;
    @media only screen and (min-width: $sm-screen) {
      width: 60%;
      padding: 0% 10%;
    }
    .header {
      h2 {
        @include h1;
        font-size: 32px;
        color: $white;
        @media only screen and (min-width: $md-screen) {
          font-size: 40px;
        }
      }
    }
    button {
      margin: $margin-md 0;
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(60px)
  }
  100% {
    opacity: 1;
    transform: translateY(0px)
  }
}
