@import '../../styles/_variables.scss';

.container {
  position: absolute;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  right: 0px;
  .profile {
    @include btn;
    display: flex;
    align-items: center;
    padding: 20px;
    a {
      display: flex;
      align-items: center;
      text-decoration: none;
      margin: 0px;
      p {
        @include text;
        margin: 0px;
        text-decoration: none;
        &:hover {
          color: $primary-color;
        }
      }
      .avatar {
        margin-left: $margin-md;
        width: 45px;
        height: 45px;
        border-radius: 45px;
        border: 1px solid $white;
        overflow: hidden;
        &:hover {
          border: 1px solid $primary-color;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    button {
      display: flex;
      align-items: center;
      @include btn;
      color: rgba($white, 0.5);
      &.active {
        transform: rotate(180deg);
      }
      &:hover {
        color: $primary-color;
      }
    }
  }
  .form {
    @include transition;
    position: fixed;
    border: $border;
    width: 200px;
    top: 50px;
    right: 20px;
    background-color: $bg-color;
    pointer-events: none;
    opacity: 0;
    &.active {
      pointer-events: all;
      top: 80px;
      opacity: 1;
    }
    button {
      @include btn;
      color: rgba($white, 0.5);
      padding: 12px;
      display: flex;
      width: 100%;
      align-items: center;
      &:not(:last-child) {
        border-bottom: $border;
      }
      svg {
        margin-right: 5px;
      }
      &:hover {
        color: $primary-color;
      }
    }
  }
}