@import '../../styles/fonts.scss';
@import '../../styles/_variables.scss';

.container {
  width: 600px;
  min-height: 500px;
  h4 {
    @include text;
    font-weight: 400;
    font-size: 18px;
  }
  label {
    @include text;
    color: $text-color;
  }
  .search {
    background-color: $dark-grey;
    padding: 10px 20px;
    border-radius: $radius;
    margin-bottom: 20px;
    input {
      @include input;
    }
  }
  .list {
    margin-bottom: 20px;
    .message {
      color: $danger;
    }
  }
}

.user {
  margin: 10px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .profile {
    display: flex;
    .avatar {
      @include avatar;
      width: 45px;
      height: 45px;
      margin-right: 10px;
    }
    p {
      @include semi-bold;
      color: $white;
      margin: 0px;
      span {
        @include regular;
        color: $text-color;
      }
    }
  }
  .actions {
    display: flex;
    align-items: center;
    .select {
      width: 110px;
    }
    .delete {
      @include btn;
    }
  }
}

.send {
  margin: 10px 0px;
  background-color: $dark-grey;
  padding: 10px 10px 10px 20px;
  border-radius: $radius;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    margin: 0px;
  }
  button {
    @include btn;
  }
}