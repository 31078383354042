@import '../../../styles/variables.scss';
@import '../form-mixin.scss';

.container-input{
  label{
    @include label;
  }

  &.inline {
    @include inline;
  }

  &:not(.inline){
    label+.input {
      margin-top: 0px;
    }
  }

  .input {
    @include input;
    max-width: 100%;
    font-family: inherit;
    font-size: 14px;
    min-height: 100px;
    min-width: 100%;
  }
  &.primary {
    @include primary;
  }
  &.login {
    @include login;
  }

}
