@import '../../styles/_variables.scss';
.container {
  border: $border;
  text-decoration: none;
  border-radius: 4px;
  @include transition;
  &:hover {
    border: 1px solid $primary-color;
  }
  .image {
    background-color: $dark-grey;
    border-top-left-radius: $radius;
    border-top-right-radius: $radius;
    height: 120px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    img {
      width: 150%;
      height: 150%;
      object-fit: contain;
    }
    button {
      @include btn;
      position: absolute;
      right: 0px;
      top: 8px;
      color: $white;
      font-size: 20px;
      &:hover {
        color: $primary-color;
      }
    }
  }
  .info {
    padding: 20px;
    h4 {
      @include regular;
      font-weight: normal;
      color: $white;
      margin: 0px 0px 8px 0px;
    }
    p {
      font-size: 14px;
      @include regular;
      margin: 0px;
    }
  }
}