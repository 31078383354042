@import '../../styles/_variables.scss';
.container {
  width: 100%;
  input {
    display: none;
  }
  .btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $dark-grey;
    border-radius: $radius;
    color: #84899A;
    font-size: 14px;
    padding: 10px;
  }
}