
@import '../../styles/variables.scss';

@mixin inline {
  display: flex;
  justify-content: space-between;
  align-items: center;
  label {
    margin-right: 10px;
  }
}

@mixin label {
  font-size: 14px;
  color: #84899A;
}

@mixin input {
    @include transition;
    display: block;
    padding: 6px 0;
    border: none;
    outline: none;
    border-radius: 0px;
    width: 100%;
    // min-width: 200px;
    caret-color: $white;
    box-sizing: border-box;
    background-color: transparent;
    border-bottom: solid 1px $dark-grey;
    font-size: $regular;
    line-height: 17px;
    color: $white;
    &:focus{
      border-bottom: solid 1px rgba($white , 0.2);
    }

    &::placeholder {
      color : #84899A;
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover, 
    &:-webkit-autofill:focus, 
    &:-webkit-autofill:active  {
      box-shadow: 0 0 0 30px $dark-grey inset !important;
      color: $white !important;
      font-size: $regular;
      -webkit-text-fill-color: $white !important;
    }
}

@mixin primary {
  .input {
    @include input;
    border-radius: $radius;
    min-height: 50px;
    background-color: rgba($white , 0.2);
    border: 2px solid #ECECF0;
    color: $black;
    @include label;
  }
  label {
    color: $black;
  }
}

@mixin login {
  .input {
    @include input;
    border-radius: $radius;
    min-height: 50px;;
    background-color: #FFFFFF;
    box-shadow: 0px 10px 28px 0px rgb(156 167 177 / 30%);
    color: $dark;
  }
  label {
    color:$dark;
  }
};