@import '../../styles/_variables.scss';

.container {
  max-width: 480px;
  .reset {
    color: #84899A;
    @include regular;
  }
  .link {
    color: #84899A;
    margin-bottom: 20px;
    font-size: 16px;
    @include regular;
    a {
      color: $primary-color;
    }
  }
  .form {
    margin-bottom: 10px;
    .container-field {
      padding: 8px 12px;
      margin-bottom: 12px;
      background-color: $dark-grey;
      border: 1px solid $dark-grey;
      border-radius: $radius;
      input {
        margin-top: 16px;
        &::placeholder {
          opacity: 0;
        } 
        @include regular;
        &:focus {
          &::placeholder {
            opacity: 0.5;
          } 
          border-bottom: 1px solid transparent;
        }
        &:not(empty) + label {
          transform: translate(0px, -16px);
        }
      }
      label {
        @include transition;
        @include regular;
        position: absolute;
        transform: translate(10px, -2px);
      }
      &:focus-within {
        border: 1px solid $primary-color;
        label {
          transform: translate(0px, -16px);
        }
      }
      &:has(input:focus) {
        border: 1px solid $primary-color;
        label {
          transform: translate(0px, -16px);
        }
      }
      &:has(input:not(empty)) {
        label {
          transform: translate(0px, -16px);
        }
      }
      &.has-value {
        label {
          transform: translate(0px, -16px);
        }
      }
    }
    .message-error{
      color : $danger;
      font-size: 12px;
    }
    .message-success {
      color : $success;
      font-size: 14px;
    }
  }
  .btn {
    display: flex;
    justify-content: flex-end;
    button {
      @include btn;
      @include regular;
    }
  }
}
