@import '../../styles/_variables.scss';
.container {
  @include aside;
  color: $text-color;
  .header {
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    a {
      @include text;
      text-decoration: none;
      color: $text-color;
      display: flex;
      align-items: center;
      margin: 0px;
      svg {
        margin-right: 5px;
        @include transition;
      }
      &:hover {
        svg {
          transform: translateX(-3px);
        }
      }
    }
    .users {
      transform: translateX(16px);
      display: flex;
      justify-content: flex-end;
      flex-direction: row-reverse;
      .user {
        @include avatar;
        &:not(:last-child) {
          transform: translateX(-6px);
        }
        &.count {
          transform: translateX(-12px);
          z-index: 100;
        }
        p {
          @include text;
          font-size: 13px;
          color: $white;
          margin: 0px;
        }
      }
    }
  }
  .nav {
    margin: 10px 0px;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    button {
      @include btn;
      width: 50%;
      @include regular;
      background: rgba($dark-grey, 0.5);
      padding: 6px 10px;
      color: $text-color;
      &:first-child {
        border-bottom-left-radius: $radius;
        border-top-left-radius: $radius;
      }
      &:last-child {
        border-bottom-right-radius: $radius;
        border-top-right-radius: $radius;
      }
      &:hover {
        background: rgba($dark-grey, 0.8);
        color: $text-color;
      }
      &.active {
        background: $dark-grey;
        color: $white;
      }
    }
  }
}