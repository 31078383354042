@import "../../../styles/variables";

.overlay-modal{
  position: fixed;
  z-index: 999;
  top:0;
  height: 100%;
  width: 100%;
  background-color: rgba($dark,0.6 );
}

.modal{
  position: fixed;
  z-index: 1000;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  min-height: 300px;
  padding: 20px;
  border-radius: $radius;
  border: $border;
  background-color: $dark;
  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    @include btn;
  }
}
