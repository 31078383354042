@import '../../styles/fonts.scss';
@import '../../styles/_variables.scss';

.comments {
  margin-top: 10px;
  max-height: calc(90vh - 300px);
  overflow: auto;
  padding: 0px 20px;
  @include scrollbar;
  .comment {
    margin-top: 15px;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        font-size: 12px;
        margin: 0px;
      }
      .profile {
        @include flex-center;
        .avatar {
          width: 30px;
          height: 30px;
          border-radius: 15px;
          overflow: hidden;
          margin-right: 8px;
          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }
        p {
          color: $white;
          font-size: 14px;
        }
      }
      margin-bottom: 8px;
    }
    p {
      font-size: 14px;
      line-height: 14px;
    }
  }
}