@import '../../styles/fonts.scss';
@import '../../styles/_variables.scss';

.container {
  width: 300px;
  .title {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .templateItem {
      border: $border;
      border-radius: 4px;
      margin-right: 12px;
      width: 50px;
      height: 50px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    h3 {
      margin: 0px;
      @include text;
      font-weight: 400;
      font-size: 18px;
    }
  }
  .options {
    display: flex;
    justify-content: space-between;
    .btn {
      @include btn;
      justify-content: center;
      align-items: center;
      padding: 10px 20px;
      border: none;
      color: $text-color;
      border-radius: $radius;
      margin-right: 5px;
      background-color: $dark-grey;
      width: calc(33.33% - 10px);
      cursor: pointer;
      &:hover {
        background-color: rgba($primary-color, 0.2);
        color: $primary-color;
      }
      &.active {
        background-color: rgba($primary-color, 0.2);
        color: $primary-color;
      }
    }
  }
  .form {
    margin-top: 20px;
    .container-field {
      padding: 8px 10px;
      margin-bottom: 10px;
      background-color: $dark-grey;
      border-radius: $radius;
      &.color {
        height: 80px;
      }
    }
    .message-error{
      color : $danger;
      font-size: 12px;
    }
    .message-success {
      color : $success;
      font-size: 14px;
    }
    .file {
      margin-top: 10px;
      width: 100%;
    }
    .submit {
      margin-top: 20px;
      display: flex;
      justify-content: center;
      .btn {
        @include btn;
      }
    }
  }
}
