@import '../../styles/fonts.scss';
@import '../../styles/_variables.scss';

.container {
  position: fixed;
  top: 60px;
  right: 30px;
  width: 300px;
  max-height: 90vh;
  background: $dark;
  transform: translateX(350px);
  color: $white;
  border: $border;
  border-radius: 4px;
  @include transition;
  &.member {
    top: 30px;
  }
  &.active {
    transform: translateX(0px);
  }
  .close {
    @include btn;
    position: absolute;
    z-index: 400;
    top: 5px;
    right: 0px;
    color: rgba($white, 0.3);
    font-size: 20px;
  }
  .content {
    position: relative;
    .form {
      overflow: auto;
      margin-top: 0px;
      padding: 20px;
      position: relative;
      max-height: 82vh;
      @include scrollbar;
      .container-field {
        margin-bottom: 15px;
        border-radius: $radius;
        &.select {
          z-index: 301;
        }
        &.color {
          height: 80px;
        }
      }
      .message-error{
        color : $danger;
        font-size: 12px;
      }
      .message-success {
        color : $success;
        font-size: 14px;
      }
    }
    .submit {
      position: absolute;
      bottom: 0px;
      left: calc(150px - 23px);
      display: flex;
      justify-content: center;
      button {
        @include flex-center;
        @include btn;
        border-radius: 23px;
        z-index: 30;
        transform: translateY(20px);
        width: 46px;
        height: 46px;
        border: $border;
        background-color: $dark;
        color: rgba($white, 0.3);
        font-size: 22px;
        &:hover {
          background-color: #412424;
          color: $primary-color;
        }
      }
    }
    .infos {
      padding: 20px;
      border-bottom: $border;
      .title {
        display: flex;
        align-items: center;
        .templateItem {
          border: $border;
          border-radius: 4px;
          margin-right: 12px;
          width: 50px;
          height: 50px;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        h3 {
          margin: 0px;
        }
      }
      p {
        margin: 20px 0px 0px 0px;
      }
    }
    .comment {
      padding: 20px 20px 0px 20px;
      textarea {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        padding: 5px 8px;
        background-color: rgba($white, 0.1);
        border-radius: $radius;
        border: 1px solid transparent;
        @include regular;
        font-size: 16px;
        caret-color: rgba($white, 0.8);
        color: $white;
        &:focus {
          outline: none;
        }
      }
      button {
        @include btn;
        @include regular;
        margin: 6px 0px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border-radius: $radius;
        font-size: 14px;
        line-height: 14px;
        padding: 5px 8px;
        color: $success;
        background-color: rgba($success, 0.1);
        border: 1px solid $success;
        &:hover {
          background-color: $success;
          color: $dark;
        }
        svg {
          margin-left: 5px;
        }
      }
    }
  }
}